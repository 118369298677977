@import "../../../assets/css/variables";


.page-card-title-divider {
    color: #1a2630;
    margin-bottom: 1rem;
    margin-top: 1rem;
    max-width: 8rem;
   
}

.page-card-text-container {
    min-height: 13rem;
    .btn {
        position: absolute;
        bottom: 1rem;
    }
}

.img-right {
    img {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
};
.img-left {
    img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    } 
}
.img-top {
    img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // @media screen and (min-width: $page-width-md) {
            
        // }
    } 
}