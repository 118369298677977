@import "../../assets/css/variables";


// ---------------------------------------------------------
// Bootstrap override to force nav-bar to right side
.navbar-collapse {
   flex-grow: 0;
}

// ---------------------------------------------------------
// Custom CSS
.header-logo {
    height: 4.124rem;
    width: 3.663rem;
}

.header-logo-text {
    display: none;
    @media screen and (min-width: $page-width-sm) {
        display: inline-flex;
        margin-left: 1rem;
    }
}

.header-contact-info {
    text-align: center;
    background-color: $brand-light-gray;
    padding: .5rem 0;

    .row {
        --bs-gutter-x: 0;
    }

    .col-sm-6 {
        text-align: center;
    }

    h6 {
        margin: 0;
    }
    img {
        height: 1rem;
        width: 1rem;
    }

    a {
        text-decoration: none;
        color: initial;
        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: $page-width-sm) {
        .col-sm-6 {
            max-width: 48%;
        }
        .col-sm-6:first-of-type {
            text-align: end;
            margin-right: .5rem;
        }    
        .col-sm-6:last-of-type {
            text-align: start;
            margin-left: .5rem;
        }      
    }
}

.header-button {
    @media screen and (min-width: $page-width-lg) {
        &.btn {
            margin: 0 0.5rem;
        }

    }
}