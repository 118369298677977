// ===============================================================
// SCSS VARIABLES

// ---------------------------------------------------------
// Variables

// Site Colors
$brand-primary: #375367;
$brand-secondary: #febaa1;
$brand-tertiary: #f394ac;
$brand-quaternary: #800062;  //RGB 128, 0, 98
$brand-neutral: #fcf3e7;
$brand-white: #ffffff;
$brand-light-gray: #f4f4f4;
$brand-body-color: #3b3b3b;
$brand-shadow: rgba(128, 0, 98, 0.5);
$brand-box-shadow: 0 0 0 0.25rem $brand-shadow;

$brand-danger: #dc3545;      // matches Bootstrap danger
$brand-warning: #ffc107;     // matches Bootstrap warning

// Page variables
$page-height: 100vh;

// Page breakpoint min-widths
$page-width-xs: 0px;
$page-width-sm: 576px;
$page-width-md: 768px;
$page-width-lg: 992px;
$page-width-xl: 1200px;
$page-width-xxl: 1400px;

// ---------------------------------------------------------
// Bootstrap Variable Overrides
// Located at:  node_modules/bootstrap/scss/_variables.scss

// Page variables
$font-family-sans-serif: "Roboto", sans-serif, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$body-color: $brand-body-color;

// Navbar
$navbar-light-active-color: $brand-primary;
$navbar-light-hover-color: $brand-primary;

.navbar-brand {
    align-items: center;
    display: flex;
}

.btn {
    &:disabled {
        background-color: $brand-quaternary;
        border-color: $brand-quaternary;
        opacity: 0.5;
    }
    &:focus-visible {
        --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(128, 0, 98, 0.5);
        border: var(--bs-btn-border-width) solid $brand-quaternary;
        background-color: $brand-quaternary;
        border-color: $brand-quaternary;
    }
}

.btn-primary {
   background-color: $brand-quaternary;
   border-color: $brand-quaternary;
   color: $brand-white;
   --bs-btn-active-bg: #66004e;
   --bs-btn-active-border-color: #66004e;

   &:hover {
    background-color: $brand-tertiary;
    border-color: $brand-tertiary;
   }
}

// Breadcrumbs
.breadcrumb {
    font-size: 1.125rem;
}

// Links
// TODO: decide on link and hover/active colors
a {
    text-decoration: none;
    color: $brand-quaternary;
    &:hover {
        color: $brand-tertiary;
    }
    &:focus-visible {
        outline-color: $brand-quaternary;
    }
}

.accordion {
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #f394ac;
    --bs-accordion-btn-icon: url("../images/icons/arrow-inactive.svg");
    --bs-accordion-btn-active-icon: url("../images/icons/arrow-active.svg");
}

.accordion-button:not(.collapsed)  {
    background-color: #FDEDF1;
    color: $brand-tertiary
}

.accordion-button:focus {
    border-color: $brand-tertiary;
}