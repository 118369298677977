@import "../../../assets/css/variables";

// ---------------------------------------------------------
// Custom CSS
.main-hero-container {
    .row {
        justify-content: center;
        align-items: center;
    }

    .btn, .btn-primary {
        margin-top: 1.5rem;
    }
    

    img {
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        max-width: 100%;
        max-height: 26rem;
    }

    @media screen and (min-width: $page-width-sm) { 
        .col-md-6:last-of-type {
            text-align: center;
        }      
    }
}