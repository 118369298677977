@import "../../../assets/css/variables";

.every-hero-container {
    padding-top: 7%;
    max-width: 1180px;
    margin: auto;
    align-items: center;

    .danger {
        color: $brand-danger;
        font-weight: 700;
        a {
            color: $brand-danger;
        }
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

   @media screen and (min-width: $page-width-lg){
        padding-top: initial;
        width: 100%
    }
}


.every-hero-image-container {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    height: 18.75rem;

    &.mobile-align-r {
        background-position-x: right;
    }

    &.mobile-align-l {
        background-position-x: left;
    }
    
    @media screen and (min-width: $page-width-md) {
        height: 25rem;
    }

    @media screen and (min-width: $page-width-lg) {
        height: 20rem;
    }
}

.every-hero-color-block {
    background-image: linear-gradient(180deg,$brand-primary 35%,rgba(255,255,255,0) 35%)!important;
    // padding: 3% 0;
    position: relative;
    background-position: 50%;
    background-size: cover;
    // margin-bottom: initial;
    --bs-gutter-x: 0;

    &.secondary {
        background-image: linear-gradient(180deg,$brand-secondary 35%,rgba(255,255,255,0) 35%)!important;
    }

    @media screen and (min-width: $page-width-sm) {
        background-image: linear-gradient(180deg,$brand-primary 44%,rgba(255,255,255,0) 44%)!important;
        &.secondary {
            background-image: linear-gradient(180deg,$brand-secondary 44%,rgba(255,255,255,0) 44%)!important;
        }
    }

    @media screen and (min-width: $page-width-lg) {
        padding: 3% 0;
        margin-bottom: 2rem;
        background-image: linear-gradient(90deg,$brand-primary 40%,rgba(255,255,255,0) 40%)!important;
        &.secondary {
            background-image: linear-gradient(90deg,$brand-secondary 40%,rgba(255,255,255,0) 40%)!important;
        }
    }
}