@import "./variables";

.breadcrumbs-services-sm {
    display: flex;
    margin-top: 2rem;
    ol {
        margin-bottom: 0;
    }
    @media screen and (min-width: $page-width-lg) {
        display: none;
    }     
}

.breadcrumbs-services-lg {
    display: none;
    @media screen and (min-width: $page-width-lg) {
        display: flex;
    }     
}