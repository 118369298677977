@import "../../../assets/css/variables";

.form-layout {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 3rem;
    max-width: 400px;
    width: 100%;

    &.wide {
        max-width: $page-width-lg;
        .row {
            display: block;
            @media screen and (min-width: $page-width-md) {
                display: flex;
            }
        }
    }
}

.form-title {
    margin-bottom: 3rem;
    text-align: center;
}

.form-input {
    margin-bottom: 1rem;

    textarea {
        height: 9.375rem;
    }

    &.grouped-block {
        .row:not(:last-child) {
            margin-bottom: 0.75rem;
        }
    }
}

.form-input-error {
    height: 1rem;
    margin-top: .25rem;
    margin-left: 1rem;
    margin-bottom: .25rem;
    display: inline-block;
}

.disclaimer {
    align-self: flex-start;
    color: #888888;
    font-size: .85rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

.form-section-divider {
    color: #1a2630;
    margin: 2rem 0;
}

.form-row-reverse {
    display: flex;
    flex-direction: column-reverse;
    @media screen and (min-width: $page-width-lg) {
        flex-direction: initial;
    }
}

.form-links {
    a {
        text-decoration: none;
        color: $brand-primary;
    
            &:hover {
                text-decoration: underline;
            }
    }
    
}

.form-button-link {
    &.btn {
        &:focus-visible {
        --bs-btn-focus-box-shadow: none;
        border: none;
        background-color: transparent;
        border-color: transparent;
        }
    }

    &.btn-link {
        color: $brand-quaternary;
        text-decoration: none;
        &:hover {
            color: $brand-tertiary;
        }
    }
    

}

// .form-contact-us-map {
//     @media screen and (min-width: $page-width-sm) {
//         margin-left: 13.666667%;
//         width: 86.33333333%;;
//     }
    
// }