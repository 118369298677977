@import "../../../assets/css/variables";

.main-stakes-cards-container {
    margin-bottom: 5rem;
    z-index: 1;
    background-color: $brand-primary;
    .col, .col-sm-6 {
        display: flex;
        align-content: center;
        justify-content: center;

        a {
            text-decoration: none;
            color: $brand-white;
            &:hover {
                text-decoration: underline;
                color: $brand-tertiary;
            }
        }
    }

  
}
