@import "../../../assets/css/variables";

.sub-hero-bg-container {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;

    &.primary {
        background-color: $brand-primary;
        color: $brand-white;
        a {
            text-decoration: none;
            color: $brand-white;
        
                &:hover {
                    text-decoration: underline;
                }
        }
        .every-sub-hero-divider {
            background-color: $brand-white;
        }
    }

    &.secondary {
        background-color: $brand-secondary;
        color: $brand-primary;
        a {
            text-decoration: none;
            color: $brand-primary;
        
                &:hover {
                    text-decoration: underline;
                }
        }
        .every-sub-hero-divider {
            background-color: $brand-primary;
        }
    }

    &.light-gray {
        background-color: $brand-light-gray;
        color: $brand-primary;

        a {
            text-decoration: none;
            color: $brand-primary;
        
                &:hover {
                    text-decoration: underline;
                }
        }
        .every-sub-hero-divider {
            background-color: $brand-primary;
        }
    }
}

.spacer {
    aspect-ratio: 2000/150;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.wave-top {
    background-image: url("../../../assets/images/wave-subhero-top.svg");
}

.wave-bottom {
    background-image: url("../../../assets/images/wave-subhero-bottom.svg");
}

.every-sub-hero-row {
    display: flex;
    justify-content: center;
}

.every-sub-hero-divider {
    max-height: .25rem;
    max-width: 95%;
    padding: 0;
    margin: 2rem 0;

    @media screen and (min-width: $page-width-md) {
        max-height: unset;
        max-width: .25rem;
        margin: 0 1rem;
    }
   
}

.every-sub-hero-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.every-sub-hero-image {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto auto;
    height: 18.75rem;
    background-position: right;
    background-size: contain;
}