@import "../../assets/css/variables";

.footer-container {
    background-color: $brand-primary;
    color: $brand-white;
    font-size: 1.125rem;

    a {
        color: $brand-white;
        margin-bottom: 1.25rem;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $brand-tertiary;
        }
    }

    .divider {
        display: block;
        margin-bottom: 1.5rem;
        background-color: $brand-white;
    }
}

.footer-logo {
    align-items: center;
    display: flex;
    justify-content: center;
}

.footer-logo-image {
    height: 4.124rem;
    width: 3.663rem;
}

.footer-logo-text {
    @media screen and (min-width: $page-width-lg) {
        margin-left: 1rem;
    }
}

.footer-office-hours {
    @media screen and (min-width: $page-width-lg) {
        margin-left: 2rem;
    }
}
    

.footer-section-title {
    color: $brand-white;
    font-weight: bold;
    margin: 1.5rem 0 1rem;
    @media screen and (min-width: $page-width-lg) {
        margin-top: 0rem;
    }
}

.footer-section-days {
    max-width: 9.438rem;
}

.footer-copyright-line-container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $page-width-md) {
        flex-direction: row;
    }
}

.footer-copyright-line {
    font-size: 1rem;

    a {
        text-decoration: underline;
    }
}