@import "../../../assets/css/_variables.scss";
@import "../../../assets/css/_base.scss";


$size-sm: 2.5rem;
$size-md: 5.5rem;
$size-lg: 8.5rem;

.every-svg-container {
    @extend .base-image-rounded;
    &.container-border { 
      border: .5rem solid 
      $brand-tertiary; 
      margin-bottom: 1rem;
    }
    &.img-bg-transparent { background-color: transparent; }
    &.img-bg-neutral { background-color: $brand-neutral; }
    &.img-bg-primary { background-color: $brand-primary; }
    &.img-bg-secondary { background-color: $brand-secondary; }
    &.img-bg-tertiary { background-color: $brand-tertiary; }
    &.img-bg-white { background-color: $brand-white; }
}

.every-svg-img {
  @extend .base-background-image;
    display: inline-block;
    &.small { height: $size-sm; width: $size-sm; }
    &.medium { height: $size-md;  width: $size-md; }
    &.large { height: $size-lg; width: $size-lg; }
    &.primary { background-color: $brand-primary; }
    &.secondary { background-color: $brand-secondary; }
    &.tertiary { background-color: $brand-tertiary; }
    &.white { background-color: $brand-white; }
  }

  /* IMAGES */

  @mixin image($url) {
    -webkit-mask: $url no-repeat 50% 50%;
    mask: $url no-repeat 50% 50%;
    -webkit-mask-size: 70%;
    mask-size: 70%;
  }

  .img-large-animal { @include image(url(../../../assets/images/icons/iconHorse.svg)); }
  .img-small-animal { @include image(url(../../../assets/images/icons/iconSmallAnimal.svg)) }
  .img-emergency { @include image(url(../../../assets/images/icons/iconEmergency.svg)) }
  .img-mobile { @include image(url(../../../assets/images/icons/iconMobileCare.svg)) }
  .img-map-icon { @include image (url(../../../assets/images/icons/map-location-dot.svg))}
  .img-phone-icon { @include image (url(../../../assets/images/icons/phone.svg))}
  .img-clock-icon { @include image (url(../../../assets/images/icons/clock.svg))}